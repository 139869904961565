import React, { useState, useEffect } from 'react';
import Layout from '../components/global/layout/Layout';
import { graphql } from 'gatsby';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import * as S from '../components/blog-page/styles';
import PostHolder from '../components/blog-page/post-holder/PostHolder';
import TopBar from '../components/blog-page/top-bar/TopBar';
import SEO from '../components/global/seo';
import FooterCTA from '../components/global/CTA/FooterCTA/FooterCTA';
import { CONSTANTS } from '../../utils/constants';
import { useLocation } from '@reach/router';
import { setColors, setFontSize, setFontWeight } from '../../utils/helpers';
const SearchBlog = ({ data }) => {
	const posts = data.allContentfulBlogPost.nodes;
	const { seoImage } = data.allContentfulHomePage.nodes[0];
	const [searchTerm, setSearchTerm] = useState('');
	const [searchedPosts, setSearchedPosts] = useState([]);
	const location = useLocation();

	useEffect(() => {
		const locationUrl = new URL(location.href);
		const term = locationUrl.searchParams.get('search');
		setSearchTerm(term);
	}, [location]);
	useEffect(() => {
		let tempSearchedPosts = posts.filter(post => {
			const regex = new RegExp(searchTerm, 'i');
			return post.title.match(regex);
		});
		setSearchedPosts(tempSearchedPosts);
	}, [searchTerm, posts]);
	return (
		<Layout>
			<SEO title={`You searched for ${searchTerm} - WotNot`} image={seoImage} />
			<WrapperOne>
				<S.Title>Blog</S.Title>
				<TopBar content="Everything" />
				<PostHolder
					search="Results for:"
					searchTerm={searchTerm}
					data={searchedPosts}
				/>
			</WrapperOne>
			<S.SearchCTAWrapper
				style={{ marginTop: searchedPosts.length !== 0 && '80px' }}
			>
				<WrapperOne>
					{searchedPosts.length === 0 && (
						<h2
							style={{
								margin: 0,
								marginBottom: '8px',
								fontSize: setFontSize.verySmall,
								lineHeight: '1.1em',
								fontWeight: setFontWeight.bolder,
								color: setColors.searchGray,
							}}
						>
							No result found
						</h2>
					)}
				</WrapperOne>
				<FooterCTA
					title={CONSTANTS.CTA_TITLE}
					subtitle={CONSTANTS.CTA_SUB_TITLE}
					link={CONSTANTS.CTA_BUTTON_LINK}
					buttonText={CONSTANTS.CTA_BUTTON_TEXT}
				></FooterCTA>
			</S.SearchCTAWrapper>
		</Layout>
	);
};
export const query = graphql`
	{
		allContentfulBlogPost(sort: { order: DESC, fields: createdAt }) {
			nodes {
				addToMostPopularPosts
				author
				category
				coverImage {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				featuredPost
				slug
				timeToRead
				title
			}
		}
		allContentfulHomePage {
			nodes {
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
			}
		}
	}
`;

export default SearchBlog;
